@import './utils.scss';

$mobileBackgroundUrl: "/assets/mobile-bg.jpg";
$blackBackgroundUrl: "/assets/watercolor-black-bg.png";
$whiteBackgroundUrl: "/assets/watercolor-white-bg.png";

#home-root {
  width: 100%;
  height: 100vh;
}

#home-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  background: url($mobileBackgroundUrl) no-repeat top center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img.special-projects-banner {
    display: none;
  }
}

#home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  width: 90%;
  max-width: 400px;
  height: 100%;

  color: rgb(54, 54, 54);

  img.mobile-banner {
    width: 100%;
  }

  h1 {
    display: none;
    justify-items: center;

    &.smaller {
      justify-items: center;
      align-items: center;
    }
  }

  p {
    text-align: center;
    font-family: 'Lora', serif;
    font-size: 2em;
    line-height: 1.5em;

    &.desktop {
      display: none;
    }

    &.mobile {
      display: initial;
    }
  }

  #navigation-elems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    font-family: 'Playfair Display', serif;
    font-size: 2.2em;

    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.25em;

    a {
      color: white;
      padding: 0 0.35em;
      margin: 5px;
    }
  }
}

@media screen and (min-width: $laptop-width) {
  #home-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #home-container {
    color: white;

    background-image: none;

    width: $laptop-width;
    height: 600px;

    margin: 0 auto;

    img.special-projects-banner {
      display: initial;
      position: relative;
      width: 200px;
      top: 15px;
      z-index: 1;
    }

    .container-bg {
      position: absolute;

      width: 100%;
      height: 100%;

      background: url($blackBackgroundUrl) no-repeat center;

      opacity: 0;

      &.light {
        background-image: url($whiteBackgroundUrl);
      }

      &.active {
        opacity: 1;
      }
    }

    &.white-theme {
      color: black;

      #home-content {
        border: none;
      }
    }
  }

  #home-content {
    display: grid;
    grid-template-rows: [header] 200px [blurb] 100px [nav] auto;
    justify-items: center;
    align-items: center;
    width: 820px;
    max-width: initial;
    height: 450px;

    position: relative;
    top: -5px;
    left: -7.5px;

    border: 1px solid white;

    color: inherit;

    img.mobile-banner {
      display: none;
    }

    h1 {
      display: initial;

      grid-row: header;

      margin: 0;

      color: inherit;
      font-family: 'Playfair Display SC', serif;
      font-size: 6em;
      letter-spacing: 0;
      text-transform: uppercase;

      justify-content: center;
      align-items: center;

      &.smaller {
        font-size: 5em;
        width: 90%;
        justify-content: center;
        align-items: center;
        justify-self: center;
        text-align: center;
      }

    }

    p {
      grid-row: blurb;

      color: inherit;
      margin-left: 5em;
      margin-right: 5em;

      &.smaller {
        font-size: 1.8em;
      }

      &.desktop {
        display: initial;
      }

      &.mobile {
        display: none;
      }
    }

    #navigation-elems {
      grid-row: nav;

      font-size: 3em;
      background: none;

      a {
        color: inherit;
        padding: 0.5em 1.15em;
        padding-bottom: 0.5em;
        margin: 0;

        border-bottom: 1px solid rgba(0, 0, 0, 0);
      }

      a:hover {
        border-bottom-color: rgba(0, 0, 0, 1);
      }
    }
  }
}

$transition-time: 500ms;

.hidden {
  opacity: 0;
}

.transition {
  transition-duration: $transition-time;
  transition-timing-function: ease-in-out;
  transition-property: border, opacity, background-color;

  &.special-projects-banner {
    transition-timing-function: cubic-bezier( 0, 0.95, 0.51, 1 );
  }
}

$parts: 1, 2, 3, 4, 5, 6;

.full-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -5;

  transition: opacity $transition-time ease-in-out;
  opacity: 0;
}

@each $partNum in $parts {
  .full-bg.part#{$partNum}-bg {
    background-image: url("/assets/part#{$partNum}-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &.active {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }

  h1 {
    font-size: 5em;
    justify-content: center;
    align-items: center;
  }
}
